import React, { useState, useEffect } from "react";
import UserProfileAPI from "../../dao/UserProfileAPI";
import ClientAPI from '../../dao/ClientAPI';
import { UserProfile } from "../../domain/Core";

async function loadUserProfile() {
	let userProfileApi = new UserProfileAPI(ClientAPI);
	return await userProfileApi.loadUserProfile();
}

export default function UserProfilePage() {
	const [userProfile, setUserProfile] = useState<null | UserProfile>(null);
	const [userProfileError, setUserProfileError] = useState<null | Error>(null);

	useEffect(() => {
		loadUserProfile()
			.then(setUserProfile)
			.catch(setUserProfileError)
	}, []);

	let userProfileContent = null;
	if (userProfileError != null) {
		userProfileContent = <UserProfileError error={userProfileError} />;
	} else if (userProfile == null) {
		userProfileContent = <UserProfileLoading />
	} else {
		userProfileContent = <UserProfileContent userProfile={userProfile} />
	}

	return (
		<div className="UserProfilePage">
			{userProfileContent}
		</div>
	);
}

function UserProfileContent(props: { userProfile: UserProfile }) {
	return(
		<div>
			<p><b>Name:</b> {props.userProfile.name}</p>
			<p><b>Email:</b> {props.userProfile.email}</p>
			<p><b>Joined:</b> {new Date(props.userProfile.created_at).toLocaleDateString('en-GB')}</p>
		</div>
	);
}

function UserProfileLoading() {
	return(
		<div className="UserProfileLoading">
			<p>Loading profile...</p>
		</div>
	);
}

function UserProfileError(props: { error: Error }) {
	return (
		<div className="UserProfileError">
			There was an error when loading the profile. Error = {props.error.message}.
		</div>
	);
}