import { Done, Close, QuestionMark, Favorite, HeartBroken, KeyboardArrowUp, KeyboardArrowDown, PlayArrow, QueryStats, Edit, Delete, Stop } from "@mui/icons-material";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, IconButton, Collapse, Box, Typography, Divider, CircularProgress, Chip } from "@mui/material";
import React, { useState } from "react";
import { Scraper, ScheduleConfig, SchedulePeriod, Scan } from "../../domain/Core";
import { format } from 'date-fns'
import { Link } from "react-router-dom";
import { getComparator, Order } from "../../utils/TableSortUtils";

function formatDate(date: string | null | undefined) {
	if (date == null) {
		return '';
	} else {
		return format(Date.parse(date), 'yyyy-MM-dd HH:mm:ss');
	}
}

export function ScrapersTable(props: {scrapers: Scraper[], runClicked: Set<number>, onEditClicked: (scraper: Scraper) => void, onTriggerClicked: (scraper: Scraper) => void, onDeleteClicked: (scraper: Scraper) => void, onStopClicked: (scan: Scan) => void}) {

	// The key in the scraper object to sort on
	const [orderBy, setOrderBy] = React.useState<keyof Scraper>('id');

	// The sort order 'asc' or 'desc'
	const [order, setOrder] = React.useState<Order>('asc');

	function handleRequestSort(property: keyof Scraper) {
		// If the key that we're currently sorting on has been selected
		// then reverse the sort order
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');

		// Set the key to sort on
		setOrderBy(property);
	}

	return (
		<TableContainer component={Paper} className="table-container" sx={{ mb: 2 }}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell key={0} style={{width: '1%'}} />
						<TableCell key={'id'} align="center" style={{width: '4%'}}>
							<TableSortLabel
								active={orderBy === 'id'}
								direction={orderBy === 'id' ? order : 'asc'}
								onClick={(e) => handleRequestSort('id')}>
								<b>ID</b>
							</TableSortLabel>
						</TableCell>
						<TableCell align="center"><b>Name</b></TableCell>
						<TableCell align="center">
							<TableSortLabel
								active={orderBy === 'source_id'}
								direction={orderBy === 'source_id' ? order : 'asc'}
								onClick={(e) => handleRequestSort('source_id')}>
								<b>Source</b>
							</TableSortLabel>
						</TableCell>
						<TableCell align="center"><b>Crawler</b></TableCell>
						<TableCell align="center"><b>Enabled</b></TableCell>
						<TableCell align="center"><b>Schedule</b></TableCell>
						<TableCell align="center"><b>Last Modified</b></TableCell>
						<TableCell align="center"><b>Health</b></TableCell>
						<TableCell align="center"><b>Status</b></TableCell>
						<TableCell align="center"><b>Actions</b></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.scrapers.sort(getComparator(order, orderBy)).map((scraper) => (
						<ScraperTableRow
							key={scraper.id} scraper={scraper}
							runClicked={props.runClicked}
							onEditClicked={props.onEditClicked}
							onTriggerClicked={props.onTriggerClicked}
							onDeleteClicked={props.onDeleteClicked}
							onStopClicked={props.onStopClicked}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function periodToString(period: SchedulePeriod) {
	let postfix = period.unit.toString().toLocaleLowerCase();
	if (period.value === 1) {
		postfix = postfix.slice(0, -1);
	}

	return `${period.value} ${postfix}`;
}

function ScraperTableRow(props: {scraper: Scraper, runClicked: Set<number>, onEditClicked: (scraper: Scraper) => void, onTriggerClicked: (scraper: Scraper) => void, onDeleteClicked: (scraper: Scraper) => void, onStopClicked: (scan: Scan) => void}) {

	const [open, setOpen] = useState(false);

	function formatSchedule(scheduleConfig: ScheduleConfig) {
		if (!scheduleConfig.enabled) {
			return '-';
		}
		if (scheduleConfig.type === 'PERIODIC') {
			return `Every ${periodToString(scheduleConfig.period)}`;
		} else {
			return 'Unknown schedule type';
		}
	}

	function formatEnabled(enabled: boolean) {
		return enabled ? <Done/> : <Close/>;
	}

	function formatSource(scraper: Scraper) {
		return <div>{scraper.source_name} <span className="source-id">({scraper.source_id})</span></div>;
	}

	function formatHealth(scraper: Scraper) {
		// There are 4 possible states
		// * Pending - (?) - no scans have been run yet
		// * Error - (red broken heart) - the last scan failed
		// * Potentially recovering - (amber heart) - the last scan succeeded but one or more of the last 5 failed
		// * Healthy - (green heart) - all recent scans succeeded
		if (!scraper.scans) {
			return <QuestionMark />
		} else {
			let lastScanStatus = scraper.scans.find(s => !['PENDING', 'RUNNING', 'STOPPED'].includes(s.status))?.status;
			if (lastScanStatus === undefined) {
				return <QuestionMark />
			}

			let hasFailedScan = scraper.scans.find(s => !['PENDING', 'RUNNING', 'STOPPED', 'FINISHED'].includes(s.status)) != null;
			if (hasFailedScan) {
				if (lastScanStatus === 'FINISHED') {
					return <Favorite style={{ color: '#ff6f00' }} />
				} else {
					return <HeartBroken color="error" />;
				}
			}

			return <Favorite color="success" />;
		}
	}

	function formatScanStats(scan: Scan) {
		let stats = scan.stats;

		let crawlerManagerStats = stats?.crawler_manager;
		if (crawlerManagerStats === undefined) {
			return 'Waiting for stats...';
		}

		let itemStorageStats = stats?.item_storage_service;
		if (itemStorageStats === undefined) {
			return 'No items created or updated';
		}

		if (crawlerManagerStats.items === undefined || itemStorageStats.items === undefined || itemStorageStats.images === undefined) {
			return 'Invalid stats';
		}

		let totalErrors = crawlerManagerStats.items.error + itemStorageStats.items.error + itemStorageStats.images.error;
		let errorsMessage = `${totalErrors} ${totalErrors === 1 ? 'error': 'errors'}`;

		return (
			<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
				<Chip label={`${itemStorageStats.items.created} created`} color="success" />
				<Chip label={`${itemStorageStats.items.updated} updated`} color="default" />
				<Chip label={errorsMessage} color="error" />
			</Box>
		);
	}

	return (
		<>
			<TableRow
				key={props.scraper.id}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell>
					{(props.scraper.scans?.length ?? 0) > 0 &&
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
						</IconButton>
					}
				</TableCell>
				<TableCell align="center" component="th" scope="row">{props.scraper.id}</TableCell>
				<TableCell align="center">{props.scraper.name}</TableCell>
				<TableCell align="center">{formatSource(props.scraper)}</TableCell>
				<TableCell align="center">{props.scraper.config.crawler}</TableCell>
				<TableCell align="center">{formatEnabled(props.scraper.schedule.enabled)}</TableCell>
				<TableCell align="center">{formatSchedule(props.scraper.schedule)}</TableCell>
				<TableCell align="center">{formatDate(props.scraper.last_modified)}</TableCell>
				<TableCell align="center">{formatHealth(props.scraper)}</TableCell>
				<TableCell align="center">{props.scraper.status}</TableCell>
				<TableCell align="center">
					<ScraperTableAction
						scraper={props.scraper}
						runClicked={props.runClicked}
						onEditClicked={props.onEditClicked}
						onTriggerClicked={props.onTriggerClicked}
						onDeleteClicked={props.onDeleteClicked}
					/>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant="h6" gutterBottom component="span">Scans</Typography>
							<Typography variant="subtitle2" component="span" marginLeft={1} >(5 most recent in the last 30 days)</Typography>

							<Table>
								<TableHead>
									<TableRow>
										<TableCell><b>ID</b></TableCell>
										<TableCell><b>Started</b></TableCell>
										<TableCell><b>Finished</b></TableCell>
										<TableCell><b>Status</b></TableCell>
										<TableCell><b>Stats</b></TableCell>
										<TableCell><b>Actions</b></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{props.scraper.scans?.slice(0, 5)?.map((scan, i) => (
										<TableRow key={scan.id}>
											<TableCell component="th" scope="row">
												{scan.id}
											</TableCell>
											<TableCell>{formatDate(scan.started_at)}</TableCell>
											<TableCell>{formatDate(scan.completed_at)}</TableCell>
											<TableCell>{scan.status}</TableCell>
											<TableCell>{formatScanStats(scan)}</TableCell>
											<TableCell>
												<ScanActions scan={scan} onStopScan={props.onStopClicked} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
}

type ScraperTableActionProps = {
	scraper: Scraper,
	runClicked: Set<number>,
	onEditClicked: (scraper: Scraper) => void,
	onTriggerClicked: (scraper: Scraper) => void
	onDeleteClicked: (scraper: Scraper) => void
};
function ScraperTableAction({scraper, runClicked, onEditClicked, onTriggerClicked, onDeleteClicked}: ScraperTableActionProps) {

	let mostRecentScan = scraper.scans?.at(0);

	return (
		<div className="actions-container">
			{runClicked.has(scraper.id ?? - 1) &&
				<>
					<CircularProgress color="success" size={18} sx={{ mt: 'auto', mb: 'auto' }} />
					<Divider orientation="vertical" flexItem />
				</>
			}
			{!(runClicked.has(scraper.id ?? -1)) && scraper.scans?.at(0)?.status !== 'RUNNING' &&
				<>
					<IconButton color="success" onClick={() => onTriggerClicked(scraper)}>
						<PlayArrow/>
					</IconButton>
					<Divider orientation="vertical" flexItem />
				</>
			}
			{mostRecentScan &&
				<Link to={`/scan/${mostRecentScan.id}/stats`}>
					<IconButton>
						<QueryStats />
					</IconButton>
				</Link>
			}
			<Divider orientation="vertical" flexItem />
			<IconButton onClick={() => onEditClicked(scraper)}>
				<Edit/>
			</IconButton>
			{ /** We can only delete scrapers with no scans */ }
			{scraper.scans?.length === 0 &&
				<IconButton color="error" onClick={() => onDeleteClicked(scraper)}>
					<Delete/>
				</IconButton>
			}
		</div>
	);
}

function ScanActions(props: {scan: Scan, onStopScan: (scan: Scan) => void}) {
	return (
		<div className="actions-container">
			{props.scan.status === 'RUNNING' &&
				<>
					<IconButton onClick={() => props.onStopScan(props.scan)} >
						<Stop color="error" />
					</IconButton>
					<Divider orientation="vertical" flexItem />
				</>
			}
			<Link to={`/scan/${props.scan.id}/stats`}>
				<IconButton>
					<QueryStats />
				</IconButton>
			</Link>
		</div>
	);
}
