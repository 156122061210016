import "./ReportList.css"
import React, { useState, forwardRef, useEffect, useRef, Ref, MutableRefObject, useLayoutEffect } from "react";
import { ReportOptions, Report, ReportEntry, Scores, ProductInfo, InstanceType, SortableReportEntry } from "../../domain/RichemontTypes";
import Button from '@mui/material/Button';
import { API } from "../../dao/RichemontAPI";
import ScoresChanger from './ScoresChanger';
import CoolLightbox from "../../components/utils/CoolLightBox";
import { getComparator, Order } from "../../utils/TableSortUtils";
import { TableSortLabel } from "@mui/material";

type ReportListProps = {
	report: Report,
	reportOptions: ReportOptions,
	indexOffset: number,
	onScoresUpdated: (productId: number, scores: Record<string, number>) => void
}

const assignRefs = <T extends unknown>(...refs: Ref<T | null>[]) => {
	return (node: T | null) => {
		refs.forEach((r) => {
			if (typeof r === "function") {
				r(node);
			} else if (r) {
				(r as MutableRefObject<T | null>).current = node;
			}
	  	});
	};
};

function calculateScoreThresholdIndex(report: Report, threshold: number) {
	for (let i = report.length - 1; i >= 0; i--) {
		let entry = report[i];
		let scores = Object.values(entry.scores);
		for (let score of scores) {
			if (score >= threshold) {
				return i + 1;
			}
		}
	}
	return report.length;
}

export default forwardRef<HTMLDivElement, ReportListProps>((props, ref) => {

	const sortableReport: SortableReportEntry[] = props.report.map((entry: ReportEntry, index: number) => ({
		...entry,
		index: index,
		total_score: entry.total_score ?? 0,
		ridged_sole_score: entry.scores.ridged_sole,
		yellow_contrast_stitching_score: entry.scores.yellow_contrast_stitching,
		non_yellow_contrast_stitching_score: entry.scores.non_yellow_contrast_stitching,
	}));

	const [moreInfoProductId, setMoreInfoProductId] = useState<null | number>(null);
	const [changeScoresEntry, setChangeScoresEntry] = useState<null | ReportEntry>(null);

	const [viewAllImages, setViewAllImages] = useState<null | string[]>(null);
	const [viewAllImagesSelectedIndex, setViewAllImagesSelectedIndex] = useState<null | number>();

	const [scrollPosition, setScrollPosition] = useState<number>(0);

	const localRef = useRef<HTMLDivElement>(null);

	// The key in the scraper object to sort on
	const [orderBy, setOrderBy] = React.useState<keyof SortableReportEntry>('index');

	// The sort order 'asc' or 'desc'
	const [order, setOrder] = React.useState<Order>('asc');

	useLayoutEffect(() => {
		if (localRef.current) {
			localRef.current.scrollTop = scrollPosition;
		}
	}, [props.report, scrollPosition])

	function handleScoresUpdated(productId: number, scores: Record<string, number>) {
		if (localRef) {
			setScrollPosition(localRef.current?.scrollTop ?? 0);
		}
		setChangeScoresEntry(null);
		props.onScoresUpdated(productId, scores);
	}

	function handleImageClicked(entry: ReportEntry, index: number) {
		setViewAllImages(entry.image_urls);
		setViewAllImagesSelectedIndex(index);
	}

	function handleViewAllClick(entry: ReportEntry) {
		setViewAllImages(entry.image_urls);
		setViewAllImagesSelectedIndex(0);
	}

	function handleRequestSort(property: keyof SortableReportEntry) {
		// If the key that we're currently sorting on has been selected
		// then reverse the sort order
		if (orderBy === property) {
			setOrder(order === "asc" ? "desc": "asc");
		} else {
			// Sort by the default order for that property
			setOrder(getDefaultOrder(property));
		}

		// Set the key to sort on
		setOrderBy(property);
	}

	function getDefaultOrder(property: keyof SortableReportEntry) {
		switch (property) {
		case "index": return "asc"
		default:
			return "desc";
		}
	}

	// Calculates the index at which items are no longer within the score threshold - they will
	// have been included for other reasons, i.e. they have been marked as counterfeit
	const scoreThresholdIndex = calculateScoreThresholdIndex(props.report, props.reportOptions.score);

	return (
		<div ref={assignRefs(localRef, ref)} className="RichemontReport">
			<table className='ReportListTable'>
				<thead>
					<tr>
						<th>
							<SortableLabel
								sortKey="index"
								order={order}
								orderBy={orderBy}
								onClick={handleRequestSort}
							>
								<b>Position</b>
							</SortableLabel>
						</th>
						<th>Product ID</th>
						<th>SKU</th>
						<th>Name</th>
						<th>Brand</th>
						<th>Identified Feature(s)</th>
						<th>Client Feedback</th>
						<th>
							<SortableLabel
								sortKey="total_score"
								order={order}
								orderBy={orderBy}
								onClick={handleRequestSort}
							>
								<b>Total Score</b>
							</SortableLabel>
						</th>
						<th>
							<SortableLabel
								sortKey="yellow_contrast_stitching_score"
								order={order}
								orderBy={orderBy}
								onClick={handleRequestSort}
							>
								<b>Yellow Stitching</b>
							</SortableLabel>
						</th>
						<th>
							<SortableLabel
								sortKey="non_yellow_contrast_stitching_score"
								order={order}
								orderBy={orderBy}
								onClick={handleRequestSort}
							>
								<b>Non Yellow Stitching</b>
							</SortableLabel>
						</th>
						<th>
							<SortableLabel
								sortKey="ridged_sole_score"
								order={order}
								orderBy={orderBy}
								onClick={handleRequestSort}
							>
								<b>Ridged Sole</b>
							</SortableLabel>
						</th>
						<th>Actions</th>
						<th>Image(s)</th>
					</tr>
				</thead>
				<tbody>
					{sortableReport && sortableReport.sort(getComparator(order, orderBy)).map((entry, index) =>
						<tr
							className={index === scoreThresholdIndex ? 'ScoreThresholdDivider' : ''}
							key={entry.id}>
							<td>{entry.index}</td>
							<td>{entry.id}</td>
							<td><a href={entry.product_info.url}>{entry.product_info.sku}</a></td>
							<td>{entry.product_info.name}</td>
							<td>{entry.product_info.brand} {entry.product_info.brand_flagged ? String.fromCodePoint(0x1F928) : ''}</td>
							<td><IdentifiedFeatures scores={entry.scores} threshold={props.reportOptions.score} /></td>
							<td><Status productInfo={entry.product_info} /></td>
							<td>{entry.total_score ?? 0}</td>
							<ScoreTableData entry={entry} name={'yellow_contrast_stitching'} reportOptions={props.reportOptions} />
							<ScoreTableData entry={entry} name={'non_yellow_contrast_stitching'} reportOptions={props.reportOptions} />
							<ScoreTableData entry={entry} name={'ridged_sole'} reportOptions={props.reportOptions} />
							<td><ReportActions onMoreInfoClick={() => setMoreInfoProductId(entry.id)} onChangeScoresClick={() => setChangeScoresEntry(entry)} /></td>
							<td><ImageSlider images={entry.image_urls} productId={entry.id} onImageClick={(selectedIndex) => handleImageClicked(entry, selectedIndex)} onViewAllClick={() => handleViewAllClick(entry)} /></td>
						</tr>
					)}
				</tbody>
			</table>
			{
				moreInfoProductId ?
					<Popup onClose={() => setMoreInfoProductId(null)}>
						<ProductInfoView productId={moreInfoProductId} />
					</Popup>
					:
					null
			}
			{
				changeScoresEntry ?
					<Popup onClose={() => setChangeScoresEntry(null)}>
						<ScoresChanger reportEntry={changeScoresEntry} onScoresUpdated={handleScoresUpdated} />
					</Popup>
					:
					null
			}
			{
				viewAllImages != null && viewAllImagesSelectedIndex != null &&
					<CoolLightbox show={viewAllImages != null} images={viewAllImages} initialSelection={viewAllImagesSelectedIndex} onClose={() => setViewAllImages(null)} />
			}
		</div>
	);
});

function SortableLabel(props: {sortKey: keyof SortableReportEntry, orderBy: string, order: Order, onClick: (orderBy: keyof SortableReportEntry) => void, children: JSX.Element}) {
	return <TableSortLabel
		sx={
			{
				'&.MuiTableSortLabel-root': {
					color: 'white',
				},
				'&.MuiTableSortLabel-root:hover': {
					color: 'white',
				},
				'&.Mui-active': {
					color: 'white',
				},
				'& .MuiTableSortLabel-icon': {
					color: 'white !important',
				}
			}
		}
		active={props.orderBy === props.sortKey}
		direction={props.orderBy === props.sortKey ? props.order : 'asc'}
		onClick={(e) => props.onClick(props.sortKey)}>
		{props.children}
	</TableSortLabel>;
}

function IdentifiedFeatures(props: { scores: Scores, threshold: number }) {
	return (
		<div className="IdentifiedFeatures">
			{
				props.scores.yellow_contrast_stitching && props.scores.yellow_contrast_stitching >= props.threshold ?
					<div>Yellow Contrast Stitching</div>
					: ''
			}
			{
				props.scores.non_yellow_contrast_stitching && props.scores.non_yellow_contrast_stitching >= props.threshold ?
					<div>Non Yellow Contrast Stitching</div>
					: ''
			}
			{
				props.scores.ridged_sole && props.scores.ridged_sole >= props.threshold ?
					<div>Ridged Sole</div>
					: ''
			}
		</div>
	);
}

function Status(props: { productInfo: ProductInfo }) {
	function extractDate(isoString: string) {
		return isoString.substring(0, 10);
	}

	if (props.productInfo.is_counterfeit === true) {
		return (
			<div className="CounterfeitBadge">
				Counterfeit
				<div className="CounterfeitBadgeDate">({extractDate(props.productInfo.feedback_updated)})</div>
			</div>
		);
	} else if (props.productInfo.is_counterfeit === false) {
		return (
			<div className="ClearedBadge">
				Cleared
				<div className="CounterfeitBadgeDate">({extractDate(props.productInfo.feedback_updated)})</div>
			</div>
		);
	} else if (props.productInfo.reason !== null) {
		return (
			<div className="TBCBadge">
				{props.productInfo.reason}
				<div className="CounterfeitBadgeDate">({extractDate(props.productInfo.feedback_updated)})</div>
			</div>
		);
	} else {
		return null;
	}
}

function ScoreTableData(props: { entry: ReportEntry, name: InstanceType, reportOptions: ReportOptions }) {
	let classes = [
		props.entry.scores[props.name] >= props.reportOptions.score ? 'Score' : 'ScoreNA',
		props.entry.original_scores[props.name] !== props.entry.scores[props.name] ? 'ScoreOverride' : '',
		'Tooltip'
	];
	return (
		<td>
			<div className={classes.join(' ')}>
				{props.entry.scores[props.name] ?? `< ${props.reportOptions.score}`}
				{
					props.entry.original_scores[props.name] !== props.entry.scores[props.name] ?
						<span className="TooltipText">Original score: {props.entry.original_scores[props.name]}</span>
						:
						null
				}
			</div>

		</td>
	);
}

function ReportActions(props: { onMoreInfoClick: () => void, onChangeScoresClick: () => void }) {
	return (
		<div className="ReportActions">
			<button onClick={props.onMoreInfoClick}>More info</button>
			<button onClick={props.onChangeScoresClick}>Change scores</button>
		</div>
	)
}

function ImageSlider(props: { images: string[], productId: number, onImageClick: (index: number) => void, onViewAllClick: (productId: number) => void }) {
	return (
		<div className="ImageSlider">
			{props.images.map((image, index) =>
				<img key={image} loading="lazy" className="RichemontImage" alt="" src={image} onClick={() => props.onImageClick(index)} />
			)}
			<Button key='view_all' onClick={() => props.onViewAllClick(props.productId)}>
				View All
			</Button>
		</div>
	);
}

function Popup(props: { onClose: () => void, children: JSX.Element }) {
	return (
		<div className='Popup' onClick={props.onClose}>
			<div className='InnerPopup' onClick={(e) => e.stopPropagation()}>
				<div className="PopupContent">
					{props.children}
				</div>
				<button className="PopupCloseButton" onClick={props.onClose}>Close</button>
			</div>
		</div>
	);
}

function ProductInfoView(props: { productId: number }) {

	const [productInfo, setProductInfo] = useState<null | Record<string, string>>(null);

	useEffect(() => {
		API.loadProductInfo(props.productId)
			.then(setProductInfo);
	}, [props.productId]);

	if (productInfo == null) {
		return <p>Loading product info...</p>
	} else {
		return (
			<div>
				<table>
					<thead>
						<tr>
							<th>Key</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody>
						{Object.entries(productInfo).map(entry =>
							<tr key={entry[0]}>
								<td>{entry[0]}</td>
								<td>{entry[1]}</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		);
	}
}
