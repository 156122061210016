import "./ReportPage.css"
import React from "react";
import ClientAPI, { Feature, Source } from "../../dao/ClientAPI";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, TablePagination, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';


type ReportPageProp = {

};

type FeatureFilter = {
	threshold: number
}

type ReportPageState = {

	// loaded sources + features info
	sources?: Source[]
	features?: Feature[]

	// report filters
	filter: {
		sources?: number[]
		features?: Record<number, FeatureFilter>
	}

	sort: {
		property: string
		ascending: boolean
	}

	// report data
	items: any[]

	// paging state
	page: number // current page
	count: number // number of items total
	pages: number // how many pages that makes
	itemsPerPage: number // how many items to show per page
};

type InfoRenderer<T> = {
	headerCount: ()=> number
	header: (func: any)=>JSX.Element
	row: (info: T)=>JSX.Element
}

function nestedJson(json: any){
	return <table>
		<tbody>
			{Object.keys(json).map(key=>(
				<tr key={key}>
					<th>{key}</th>
					<td>{
						typeof json[key] === "object"?
							nestedJson(json[key]):
							json[key]
					}</td>
				</tr>))}
		</tbody>
	</table>
}

// this renders out the item info as a table
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BasicInfoRenderer: InfoRenderer<any> = {
	header: ()=><th>Info</th>,
	headerCount: ()=>1,
	row: (info: any)=><td>{nestedJson(info)}</td>
}

type InfoWithBrand = any & {Brand: string};
const BrandInfoRenderer: InfoRenderer<InfoWithBrand> = {
	header: ()=> <th>Brand</th>,
	headerCount: ()=>1,
	row: (info: InfoWithBrand)=><><td>{info.Brand ?? info.brand}</td></>
}

function BuildFeatureRenderer(name: string, id: number){
	const FeatureRenderer: InfoRenderer<any> = {
		header: (sortableHeader: any)=> sortableHeader(name, "feature:"+id),
		headerCount: ()=>1,
		row: (item: any)=><td key={name}>{item.features[id] ?? '-'}</td>
	}
	return FeatureRenderer;
}

// ! This class will need refactoring into smaller production ready components if we plan to use it in the long term!

export default class ReportPage extends React.Component<ReportPageProp, ReportPageState>{
	constructor(props: ReportPageProp){
		super(props);

		this.state = {

			// todo configure filter via UI and args
			filter: {
				features: {
					1: {threshold: 0}
				}
			},

			sort: {
				property: "features",
				ascending: false
			},

			items: [],

			page: 0,
			pages: 0,
			count: 0,
			itemsPerPage: 50
		}
	}

	componentDidMount() {
		this.updateData();
	}

	async updateData(){
		// get supporting data
		if(!this.state.sources){
			ClientAPI.source.list().then((data)=>{
				if (data == null) {
					// TODO handle error
					return;
				}
				this.setState({sources: data});
				this.updateFilter({sources: data.map(s=>s.id)});
			});
		}
		if(!this.state.features)
			ClientAPI.feature.list().then((data)=>{
				if (data == null) {
					// TODO handle error
					return;
				}
				this.setState({features: data})
			});

		// get the page data
		let data = await ClientAPI.report.get(this.state.filter, this.state.sort, this.state.page);
		if(this.state.page === data.page){
			this.setState({count: parseInt(''+data.count), pages: parseInt(''+data.pages), items: data.items});
		}
	}

	componentDidUpdate(prevProps: Readonly<ReportPageProp>, prevState: Readonly<ReportPageState>, snapshot?: any): void {
		if(this.state.page !== prevState.page){
			this.updateData();
			return;
		}

		if(JSON.stringify(this.state.filter) !== JSON.stringify(prevState.filter)){
			this.updateData();
			return;
		}

		if(JSON.stringify(this.state.sort) !== JSON.stringify(prevState.sort)){
			this.updateData();
			return;
		}
	}

	updateFilter(partial: any){
		let f = this.state.filter;
		this.setState({page: 0, filter: Object.assign({}, f, partial)});
	}

	render(): React.ReactNode {

		// TODO we'll want to swap out the info renderer to render different things for different sources
		let infoRenderer: InfoRenderer<any> = BrandInfoRenderer;

		if(!this.state.sources || !this.state.features){
			return <h1>LOADING...</h1>
		}

		// build an array of renderers for the columns of the table
		let features = this.state.features;
		let featureRenders: InfoRenderer<any>[] = [];
		for(let feature of features){
			// hide columns for features we aren't showing
			if(this.state.filter?.features){
				if(!this.state.filter.features[feature.id])
					continue;
			}
			// generate columns
			featureRenders.push(BuildFeatureRenderer(feature.name, feature.id));
		}

		let sortFunction = (property: string)=>{
			if(this.state.sort.property !== property){
				this.setState({sort: {property: property, ascending: false}})
			}else{
				this.setState({sort: {property: property, ascending: !this.state.sort.ascending}})
			}
		}

		let sortableHeader = (name: string, property: string, colspan=1)=>
			<th className="sortable" onClick={()=>sortFunction(property)} colSpan={colspan} key={property}>
				{name} {this.state.sort.property===property?<Icon.ArrowDropDown className={this.state.sort.ascending?"sort flip":"sort"}/>:''}
			</th>

		return <div className="ReportPage">
			{/* FILTERS */}
			<Accordion>
				<AccordionSummary expandIcon={<Icon.ExpandMore />}>
					<Typography>Filters</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div className="filters">
						<div>
							<h3>Sources</h3>
							{this.state.sources.map(s=>
								<div key={s.id}>
									<label>
										<Checkbox
											checked={this.state.filter.sources?.includes(s.id)}
											onChange={(event, checked)=>{
												checked?
													this.updateFilter({sources: [s.id, ...(this.state.filter.sources ?? [])]}):
													this.updateFilter({sources: this.state.filter.sources?.filter(v=>v!==s.id)})
											}}/>
										{s.name}</label>
								</div>
							)}
						</div>
						<div>
							<h3>Features</h3>
							{this.state.features.map(f=>
								<div key={f.id}>
									<label>
										<Checkbox
											checked={this.state.filter.features?.[f.id] != null}
											onChange={(event, checked)=>{
												let nFeatures = Object.assign({},this.state.filter.features);
												if(!checked){
													delete nFeatures[f.id];
												}else{
													nFeatures[f.id] = {threshold: 0}
												}
												this.updateFilter({features: nFeatures});
											}}/>
										{f.name} ({f.id})</label>
								</div>
							)}
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

			{/* PAGING TOP */}
			<TablePagination
				component="div"
				count={this.state.count}
				page={this.state.page}
				onPageChange={(event, page)=>{this.setState({page: page})}}
				rowsPerPage={this.state.itemsPerPage}
				onRowsPerPageChange={()=>{}}
				rowsPerPageOptions={[this.state.itemsPerPage]}
			/>

			{/* RESULTS TABLE */}
			<table>
				<thead>
					<tr>
						<th colSpan={infoRenderer.headerCount() + 2}>Info</th>
						{sortableHeader("Features", "features" , featureRenders.length)}
						<th></th>
						<th></th>
					</tr>
					<tr>
						{sortableHeader("ID", "id")}
						<th>Images</th>
						{infoRenderer.header(sortableHeader)}
						{featureRenders.map(f=>f.header(sortableHeader))}
						{sortableHeader("Created", "created")}
						{sortableHeader("Updated", "updated")}
					</tr>
				</thead>
				<tbody>
					{
						this.state.items.length===0?
							<tr><td colSpan={100}>No items found</td></tr>
							:
							this.state.items.map(i=>
								<tr key={i.id} className="itemRow">
									<td>{i.id}</td>
									<td>
										<a href={`/#/item/${i.item.source_id}/${i.item.external_ref}`}>
											<div className="imgCenter">
												<img src={i.images.length > 0 ? i.images[0].url : ""} alt=""/>
											</div>
										</a>
									</td>
									{infoRenderer.row(i.item.info)}
									{featureRenders.map(f=>f.row(i))}
									<td>{i.item.created_at}</td>
									<td>{i.item.updated_at}</td>
								</tr>)
					}
				</tbody>
			</table>

			{/* PAGING BOTTOM  */}
			<TablePagination
				component="div"
				count={this.state.count}
				page={this.state.page}
				onPageChange={(event, page)=>{this.setState({page: page})}}
				rowsPerPage={this.state.itemsPerPage}
				onRowsPerPageChange={()=>{}}
				rowsPerPageOptions={[this.state.itemsPerPage]}
			/>
		</div>;
	}
}
