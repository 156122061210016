import { ReportOptions } from "../domain/RichemontTypes";
import { User } from "../domain/Core.js";

class RichemontAPI {

	url: string;
	user: User | null = null;

	setUser(user: User){
		this.user = user;
	}

	constructor() {
		let url = window.__RUNTIME_CONFIG__.REACT_APP_PORTAL_SERVER;
		if (url === undefined) {
			throw new Error("The API URL has not been configured as an environment variable.");
		}
		this.url = url;
	}

	async request(url: string | URL, options: RequestInit = {}) {
		try {
			// Get auth token
			let token = await this.getAuthToken();
			if (!token) {
				throw new Error(`Request not attempted - user has no valid token`);
			}

			// Add token to header
			options['headers'] = {
				...(options.headers ?? {}),
				authorization:`MSAL:Bearer ${token}`
			};

			// Make request
			let resp = await fetch(url, options);
			if (resp.status !== 200) {
				console.log(`Request error - ${url} - status=${resp.status}`);
				throw new Error(await this.createErrorMessage(resp));
			}

			// Parse response
			let jsonData = await resp.json();
			return jsonData['data'];
		} catch (e) {
			console.log(`Request error - ${url} - exception=${e}`);
			throw e;
		}
	}

	async getAuthToken() {
		if (this.user?.system === "MSAL") {
			return await this.user.token();
		} else {
			return null;
		}
	}

	async createErrorMessage(response: Response): Promise<string> {
		let errorInfo = {
			status: response.status,
			statusText: response.statusText
		};
		return `Request error: ${JSON.stringify(errorInfo)}`;
	}

	async loadReportData(reportOptions: ReportOptions) {
		let url = new URL(`${this.url}/richemont/report`);
		for (let [key, value] of Object.entries(reportOptions)) {
			url.searchParams.append(key, value.toString());
		}

		return await this.request(url);
	}

	async loadImages(productID: number) {
		return await this.request(`${this.url}/richemont/product/${productID}/images`);
	}

	async loadProductInfo(productId: number) {
		return await this.request(`${this.url}/richemont/product/${productId}`);
	}

	async writeScore(productId: number, name: string, score: number) {
		return await this.request(`${this.url}/richemont/score`, {
			method: 'POST',
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				productId: productId,
				instanceType: name,
				score: score
			})
		});
	}
}

export const API = new RichemontAPI();
