import { Box, Card, CardMedia, Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import ErrorCard from "../../components/utils/ErrorCard";
import ClientAPI from "../../dao/ClientAPI";
import { CountsData } from "../../domain/Core";
import { Line } from 'react-chartjs-2';
import type { ChartOptions } from 'chart.js';
import { Chart as ChartJS, TimeSeriesScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import "chartjs-adapter-date-fns";
import LoadingView from "../../components/utils/LoadingView";
import { useQuery } from 'react-query'

export default function ScanStatsPageParamValidation() {

	let {scanId: scanIdString } = useParams();
	let scanId = scanIdString !== undefined ? parseInt(scanIdString) : undefined;

	if (scanId === undefined || isNaN(scanId)) {
		return <ErrorCard error={new Error("Invalid scan id parameter")} />;
	}

	return <ScanStatsPage scanId={scanId} />
}

function ScanStatsPage(props: {scanId: number}) {

	const { status, error, data: scan, refetch } = useQuery(['scan', props.scanId], () => ClientAPI.scan.get(props.scanId));

	if (status === 'loading' || status === 'idle') {
		return <LoadingView />
	}

	if (status === 'error') {
		return <ErrorCard error={error} onRetry={refetch} />
	}

	if (scan.stats == null) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight="100%"
			>
				No stats have been tracked for this scan yet.
			</Box>
		);
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', justifyContent:'center', alignContent: 'center', m: 1, p: 1 }}>
			<h2>Totals</h2>
			<Paper sx={{ p: 1 }}>
				<pre>
					{JSON.stringify(scan.stats, null, 3)}
				</pre>
			</Paper>
			<h2>Time Series</h2>
			<ExtraStatsComponent scanId={props.scanId} />
			<h2>Recently Created Items</h2>
			<RecentItems scanId={props.scanId} />
		</Box>
	)
}

// The timestamps returned by the stats service are in seconds not milliseconds - the
// chart library requires them to be Date objects
function timestampToDate(timestamp: number) {
	return new Date(timestamp * 1000);
}

function extractDate(data: CountsData) {
	return timestampToDate(data[1]);
}

function extractValue(data: CountsData) {
	return data[0];
}

function ExtraStatsComponent(props: { scanId: number }) {

	const { status, error, data: extraStats, refetch } = useQuery(['extraScanStats', props.scanId], () => ClientAPI.scan.getExtraStats(props.scanId));

	if (status === 'error') {
		return <ErrorCard error={error} onRetry={refetch} />
	}

	if (status === 'loading' || status === 'idle') {
		return <div>Loading time series data...</div>;
	}

	if (extraStats === null) {
		return <div>No time series data available yet. Try again later.</div>
	}

	return (
		<Grid container spacing={2} p={2}>
			<Grid item xs={12} p={2}>
				<TimeSeriesChart
					title="Crawler Manager"
					data={[
						{
							labels: extraStats.crawler_manager.items.total.map(extractDate),
							data: extraStats.crawler_manager.items.total.map(extractValue),
							title: 'Total',
							color: [0.5, 125, 0.5]
						},
						{
							labels: extraStats.crawler_manager.items.cached.map(extractDate),
							data: extraStats.crawler_manager.items.cached.map(extractValue),
							title: 'Cached',
							color: [0.5, 0.5, 125]
						},
						{
							labels: extraStats.crawler_manager.items.error.map(extractDate),
							data: extraStats.crawler_manager.items.error.map(extractValue),
							title: 'Errors',
							color: [125, 0.5, 0.5]
						}
					]}
				/>
			</Grid>
			<Grid item sm={12} md={6} p={2}>
				<TimeSeriesChart
					title="Item Storage Service (items)"
					data={[
						{
							labels: extraStats.item_storage_service.items.created.map(extractDate),
							data: extraStats.item_storage_service.items.created.map(extractValue),
							title: 'Total',
							color: [0.5, 125, 0.5]
						},
						{
							labels: extraStats.item_storage_service.items.updated.map(extractDate),
							data: extraStats.item_storage_service.items.updated.map(extractValue),
							title: 'Cached',
							color: [0.5, 0.5, 125]
						},
						{
							labels: extraStats.item_storage_service.items.error.map(extractDate),
							data: extraStats.item_storage_service.items.error.map(extractValue),
							title: 'Errors',
							color: [125, 0.5, 0.5]
						}
					]}
				/>
			</Grid>
			<Grid item sm={12} md={6} p={2}>
				<TimeSeriesChart
					title="Item Storage Service (images)"
					data={[
						{
							labels: extraStats.item_storage_service.images.created.map(extractDate),
							data: extraStats.item_storage_service.images.created.map(extractValue),
							title: 'Total',
							color: [0.5, 125, 0.5]
						},
						{
							labels: extraStats.item_storage_service.images.downloaded.map(extractDate),
							data: extraStats.item_storage_service.images.downloaded.map(extractValue),
							title: 'Cached',
							color: [0.5, 0.5, 125]
						},
						{
							labels: extraStats.item_storage_service.images.error.map(extractDate),
							data: extraStats.item_storage_service.images.error.map(extractValue),
							title: 'Errors',
							color: [125, 0.5, 0.5]
						}
					]}
				/>
			</Grid>
		</Grid>
	);

}

ChartJS.register(
	TimeSeriesScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

type ChartData = {
	labels: Date[],
	data: number[],
	title: string,
	color: [number, number, number]
};

function TimeSeriesChart(props: {title: string, data: ChartData[]}) {

	const options: ChartOptions<"line"> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: true,
				text: props.title,
			},
		},
		scales: {
			y: {
				beginAtZero: true
			},
			x: {
				type: "timeseries",
				time: {
					minUnit: "second",
					displayFormats: {
						hour: 'HH:mm',
						minute: 'HH:mm',
						second: 'HH:mm:ss'
					}
				}
			}
		},
		spanGaps: true
	};

	const data = {
		labels: props.data[0].labels,
		datasets: props.data.map(d => {
			return {
				label: d.title,
				data: d.data,
				borderColor: `rgb(${d.color[0]}, ${d.color[1]}, ${d.color[2]})`,
				backgroundColor: `rgb(${d.color[0]}, ${d.color[1]}, ${d.color[2]}, 0.5)`
			};
		})
	};

	return <Line height={400} options={options} data={data} />;
}

function RecentItems(props: {scanId: number}) {

	const { status, error, data: recentItems, refetch } = useQuery(['recentScanItems', props.scanId], () => ClientAPI.scan.getRecentItems(props.scanId));

	if (status === 'error') {
		return <ErrorCard error={error} onRetry={refetch} />
	}

	if (status === 'loading' || status === 'idle') {
		return <div>Loading recently created items...</div>;
	}

	if (recentItems === null) {
		return <div>No recent items data available yet. Try again later.</div>
	}

	return (
		<div>
			{recentItems.map((item: any) => (
				<RecentItem key={item.id} item={item} />
			))}
		</div>
	)

}

function RecentItem(props: {item: any}) {
	return (
		<Card sx={{ padding: "16px", marginTop: "16px", marginBottom: "16px" }}>
			<Grid container gap={2}>
				{props.item.images.map((image: any) => (
					<Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={image.id}>
						<CardMedia
							component={"img"}
							src={image.url}
							alt={image.id}
							height={300}
							loading="lazy"
							sx={{ objectFit: "contain" }}
						/>
					</Grid>
				))}
			</Grid>
			<pre>{JSON.stringify(props.item, null, "\t")}</pre>
		</Card>
	)
}
