export type Order = 'asc' | 'desc';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
	  	return -1;
	}
	if (b[orderBy] > a[orderBy]) {
	  	return 1;
	}
	return 0;
}

export function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
	return (a, b) => (order === 'desc' ? 1 : -1) * descendingComparator(a, b, orderBy);
}
