import { Dialog, DialogTitle, DialogContent, FormControl, FormGroup, FormLabel, TextField, MenuItem, DialogActions, Button } from "@mui/material";
import React, { useState } from "react";
import { Source } from "../../dao/ClientAPI";
import { ScraperConfig, Tag } from "../../domain/Core";
import ScheduleForm from "./ScheduleForm";
import ScraperConfigForm from "./ScraperConfigForm";
import TagsForm from "./TagsForm";

function parseJSON(toParse: string) {
	try {
		return JSON.parse(toParse);
	} catch (e) {
		console.log(`Error parsing json - ${e}`);
		return null;
	}
}

function removeTagsFromConfig(config: Record<string, any>) {
	let newConfig = {...config};
	delete newConfig.tags;
	return newConfig;
}

type CreateScraperFormProps = {
	open: boolean,
	sources: Source[],
	tags: Tag[],
	scraper: ScraperConfig,
	onSubmit: (scraper: ScraperConfig) => void,
	onClose: () => void,
	onTagCreated: (tag: Tag) => void
}
export default function CreateScraperDialog(props: CreateScraperFormProps) {

	const [scraper, setScraper] = useState(props.scraper);

	const [crawlerConfig, setCrawlerConfig] = useState<string>(JSON.stringify(removeTagsFromConfig(props.scraper.config), null, '\t'));

	const [nameError, setNameError] = useState(false);
	const [sourceError, setSourceError] = useState(false);
	const [configError, setConfigError] = useState(false);

	const [selectedTags, setSelectedTags] = useState<number[]>(props.scraper.config.tags ?? []);

	function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		// Clear previous error
		setNameError(false);

		let candidateName = event.target.value;

		// Validate name
		if (candidateName.length === 0 || candidateName.length > 60) {
			setNameError(true);
		}

		setScraper({
			...scraper,
			name: candidateName
		});
	};

	const handleSourceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let sourceIdString = event.target.value;
		let sourceId = parseInt(sourceIdString);
		let source = props.sources.find((s) => s.id === sourceId)
		if (source) {
			setSourceError(false);
			setScraper({
				...scraper,
				source_id: source.id,
				source_name: source.name
			});
		}
	};

	function handleCrawlerConfigChanged(config: string) {
		let parsedConfig = parseJSON(config);
		if (parsedConfig == null) {
			setConfigError(true);
		} else {
			setConfigError(false);
		}

		setCrawlerConfig(config);
	}

	function handleSave() {
		if (scraper.name === null || nameError) {
			setNameError(true);
			return;
		}

		if (scraper.source_id === null || sourceError) {
			setSourceError(true);
			return;
		}

		let config;
		try {
			config = JSON.parse(crawlerConfig);

			// Add tags back to config
			config.tags = selectedTags;
		} catch (e) {
			setConfigError(true);
			return;
		}

		props.onSubmit({
			...scraper,
			config: {
				...config
			}
		});
	}

	return (
		<Dialog open={props.open ?? false} onClose={props.onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Configure scraper</DialogTitle>
			<DialogContent>
				<FormControl fullWidth sx={{ gap: 2 }}>
					<FormGroup sx={{ gap: 2 }}>
						<FormLabel component="legend">Choose a name</FormLabel>
						<TextField
							onChange={handleNameChange}
							label="Name"
							value={scraper.name ?? ''}
							fullWidth
							error={nameError}
						/>
					</FormGroup>

					<FormGroup sx={{ gap: 2 }}>
						<FormLabel component="legend">Configure a source</FormLabel>
						<TextField
							value={scraper.source_id?.toString() ?? ''}
							onChange={handleSourceIdChange}
							select
							label="Select the source"
							fullWidth
							error={sourceError}
						>
							{props.sources.map((source, i) =>
								<MenuItem key={i} value={source.id}>
									{source.name} - {source.id}
								</MenuItem>
							)}
						</TextField>
					</FormGroup>

					<ScheduleForm
						schedule={scraper.schedule}
						onScheduleChanged={(schedule) => setScraper({...scraper, schedule: {...schedule}})} />

					<TagsForm
						tags={props.tags}
						selectedTags={selectedTags}
						onTagSelectionUpdated={setSelectedTags}
						onTagCreated={props.onTagCreated}
					/>

					<ScraperConfigForm crawlerConfig={crawlerConfig} showError={configError} onConfigChanged={handleCrawlerConfigChanged} />

				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button onClick={handleSave}>{props.scraper.id == null ? "Create" : "Update"}</Button>
			</DialogActions>
		</Dialog>
	)
}
