import { Box, Card, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useParams } from "react-router-dom";
import ClientAPI from "../../dao/ClientAPI";
import { useQuery } from 'react-query'
import ErrorCard from "../../components/utils/ErrorCard";
import LoadingView from "../../components/utils/LoadingView";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ItemPage.css";
import { useState } from "react";
import CoolLightbox from "../../components/utils/CoolLightBox";

export default function ItemPage() {

	let { source, itemRef } = useParams();

	if (source === undefined || isNaN(parseInt(source)) || itemRef === undefined) {
		return <ErrorCard error={new Error("Invalid query parameters")} />
	}

	return <ItemWidget source={parseInt(source)} itemRef={itemRef} />
}

export function ItemWidget(props: { source: number, itemRef: string }) {

	const { status, error, data: item, refetch } = useQuery(['item', props.source, props.itemRef], () => {
		return ClientAPI.item.get(props.source, props.itemRef);
	});

	if (status === 'loading' || status === 'idle') {
		return <LoadingView />
	}

	if (status === 'error') {
		return <ErrorCard error={error} onRetry={refetch} />
	}

	if (item == null) {
		return <h1>Item not found</h1>
	}

	return (
		<Box className="itemPage" sx={{ display: 'flex', flexDirection: 'column', gap: '4px', justifyContent: 'center', alignContent: 'center', m: 1, p: 1 }}>
			<ItemSection item={item} />
			<ImagesSection images={item.images} />
		</Box>
	)

}

function ItemSection(props: { item: any }) {

	let info = extractKeys(props.item, ['id', 'source_id', 'external_ref', 'created_at', 'updated_at']);
	let metaData = props.item.info as Record<string, string>;

	return (
		<>
			<h2>Item</h2>
			<Grid container>
				<Grid item md={6}>
					<h3>Info</h3>
					<DataTable data={info} />
				</Grid>
				<Grid item md={6}>
					<h3>Metadata</h3>
					<DataTable data={metaData} />
				</Grid>
			</Grid>
		</>
	);
}

function extractKeys(data: any, keys: string[]) {
	let extracted: Record<string, string> = {};
	for (let key of keys) {
		if (key in data) {
			extracted[key] = data[key];
		}
	}
	return extracted;
}

function DataTable(props: { data: Record<string, string> }) {
	return (
		<TableContainer component={Card} className="table-container" sx={{ m: 2, width: 'auto' }}>
			<Table>
				<TableBody>
					{Object.entries(props.data).map(([key, value]) =>
						<TableRow
							key={key}
							sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
						>
							<DataTableEntry title={key} value={value} />
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function DataTableEntry(props: { title: string, value: string }) {
	return <>
		<DataHeaderCell title={props.title} />
		<TableCell>{props.value}</TableCell>
	</>
}

function DataHeaderCell(props: { title: string }) {
	return (
		<TableCell sx={{ fontWeight: "bold", borderRight: "0px solid" }} >
			{formatHeaderTitle(props.title)}
		</TableCell>
	);
}

function formatHeaderTitle(text: string) {
	// Capitalise first letter
	// text = text[0].toLocaleUpperCase() + text.slice(1);

	// Replace '_' with space
	text = text.replaceAll('_', ' ');
	// text = text.toLocaleUpperCase();
	return text;
}

function ImagesSection(props: { images: Record<string, string>[] }) {

	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const [imageToView, setImageToView] = useState<any>(null);

	function handleImageChanged(index: number) {
		setSelectedImageIndex(index);
	}

	function showImageViewer(image: any) {
		setImageToView(image);
	}

	function closeImageViewer() {
		setImageToView(null);
	}

	let emptyData = {
		'…': '…'
	};

	return (
		<>
			<h2>Images</h2>
			<Grid container>
				<Grid item md={6}>
					<Box sx={{ m: 2 }}>
						<Carousel onChange={handleImageChanged} showArrows={false} showStatus={false}>
							{props.images.map(i =>
								<div key={i.id} className="carouselImgContainer" onClick={() => showImageViewer(i)}>
									<img alt="" src={i.url} />
								</div>
							)}
						</Carousel>
					</Box>
				</Grid>
				<Grid item md={6}>
					<h3>Info</h3>
					<DataTable data={props.images[selectedImageIndex]} />

					<h3>Scores (coming soon)</h3>
					<DataTable data={emptyData} />

					<h3>Features (coming soon)</h3>
					<DataTable data={emptyData} />
				</Grid>
			</Grid>
			{imageToView &&
				<CoolLightbox show={imageToView != null} images={props.images.map(i => i.url)} initialSelection={selectedImageIndex} onClose={closeImageViewer} />
			}
		</>
	)
}
