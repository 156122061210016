import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { State, User } from "../../domain/Core.js";
import "./LoginPage.css"

import Button from '@mui/material/Button';
import { useEffect } from "react";

export default function LoginPage(props: {user: State<User>}){
	const { instance, accounts, inProgress } = useMsal();

	useEffect(()=>{
		if (inProgress === InteractionStatus.None && accounts.length > 0) {
			// check if we have now (automatically) logged in - update the application state if so
			if(props.user.value?.username !== accounts[0].username){
				// after we've finished rendering - instruct the Application that we have changed the User
				props.user.onChange({
					system: "MSAL",
					username: accounts[0].username,
					token: async ()=>{
						let res = await instance.acquireTokenSilent({scopes: [], account: accounts[0], forceRefresh: false})
						return res.accessToken;
					}
				});
			}
		}
	}, [inProgress, accounts, instance, props]);

	if (inProgress === InteractionStatus.None && accounts.length > 0) {
		// this will only render for a fraction of a second....
		return <section className="LoginPage">
			Logging in...{accounts[0].username}
		</section>
	} else if (inProgress === InteractionStatus.Login) {
		// Todo spinner
		return <section className="LoginPage">Login is currently in progress!</section>
	} else {
		return (
			<section className="LoginPage">
				<div>
					<div>Please sign in</div>
					<Button variant="contained" onClick={async () => {
						let request = {
							scopes: ['user.read']
						}
						let data = await instance.loginPopup(request);
						if(data.account && data.account.username){
							let account: AccountInfo = data.account;
							props.user.onChange({
								system: "MSAL",
								username: account.username,
								token: async ()=>{
									let res = await instance.acquireTokenSilent({scopes: [], account: account, forceRefresh: false})
									return res.accessToken;
								}
							});
						}
					}}>Login</Button>
				</div>
			</section>
		);
	}
}
