import { ClientAPI } from "./ClientAPI.js";

export default class UserProfileAPI {
	
	private api: ClientAPI;
	
	constructor(api: ClientAPI) {
		this.api = api;
	}

	async loadUserProfile(): Promise<any> {
		return this.api._get(`/user/profile`);
	}
}