import "./RichemontReportPage.css"
import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { ReportOptions, Report } from "../../domain/RichemontTypes";
import { API } from "../../dao/RichemontAPI";
import ReportSummary from "./ReportSummary";
import ReportList from "./ReportList";

const PRODUCTS_PER_PAGE = 500;

async function loadReport(reportOptions: ReportOptions) {
	return await API.loadReportData(reportOptions);
}

function getReportPage(report: Report, pageNumber: number) {
	let offset = pageNumber * PRODUCTS_PER_PAGE;
	return report.slice(offset, offset + PRODUCTS_PER_PAGE);
}

export default function ReportPage() {

	const DEFAULT_REPORT_OPTIONS: ReportOptions = {
		score: 0.9,
		freshnessDays: 7,
		includeRidgedSoleOnly: false,
		includeStitchingOnly: true,
		includeCleared: false,
		includeDMs: false
	};

	const [reportOptions, setReportOptions] = useState<ReportOptions>(DEFAULT_REPORT_OPTIONS);
	const [report, setReport] = useState<null | Report>(null);
	const [reportError, setReportError] = useState<null | Error>(null);
	const [pageNumber, setPageNumber] = useState(0);
	const [offset, setOffset] = useState<number>(0);

	async function handleScoresUpdated(productId: number, scores: Record<string, number>) {
		setOffset(window.scrollY);
		loadReport(reportOptions)
			.then(setReport);
	}

	useEffect(() => {
		setReportError(null)
		setReport(null);
		setOffset(0);
		setPageNumber(0);
		loadReport(reportOptions)
			.then(setReport)
			.catch(setReportError);
	}, [reportOptions]);

	const reportListRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		reportListRef.current?.scrollIntoView({ behavior: 'auto' });
	}, [pageNumber]);

	useEffect(() => {
		if (report != null) {
			window.scrollTo(0, offset);
		}
	}, [report, offset]);

	let reportContent = null;
	if (reportError != null) {
		reportContent = <ReportError error={reportError} />;
	} else if (report == null) {
		reportContent = <ReportLoading />;
	} else if (report.length === 0) {
		reportContent = <ReportEmpty />;
	} else {
		reportContent = (
			<div>
				<ReportSummary report={report} reportOptions={reportOptions} />
				<ReportList ref={reportListRef} report={getReportPage(report, pageNumber)} reportOptions={reportOptions} indexOffset={pageNumber * PRODUCTS_PER_PAGE} onScoresUpdated={handleScoresUpdated} />
				{
					report.length > PRODUCTS_PER_PAGE ?
						<Pagination report={report} pageNumber={pageNumber} onPageChange={setPageNumber} />
						:
						null
				}
			</div>
		);
	}

	return (
		<div className="RichemontReportPage">
			<ReportOptionsComponent reportOptions={reportOptions} onOptionsUpdated={setReportOptions} />
			{reportContent}
		</div>
	);
}

function Pagination(props: { report: Report, pageNumber: number, onPageChange: (pageNumber: number) => void }) {

	const numPages = Math.ceil(props.report.length / PRODUCTS_PER_PAGE);

	return (
		<div className="Pagination">
			{
				props.pageNumber !== 0 ?
					<button onClick={() => props.onPageChange(props.pageNumber - 1)}>Previous</button> : null
			}

			{props.pageNumber + 1} of {numPages}

			{
				props.pageNumber !== numPages - 1 ?
					<button onClick={() => props.onPageChange(props.pageNumber + 1)}>Next</button> : null
			}
		</div>
	)
}

function ReportLoading() {
	return <div className="ReportLoading">Loading report...</div>;
}

function ReportEmpty() {
	return (
		<div className="ReportEmpty">
			The report is empty. Try lowering the 'Score' or increasing 'Freshness days'.
		</div>
	);
}

function ReportError(props: { error: Error }) {
	return (
		<div className="ReportError">
			There was an error when loading the report. Error = {props.error.message}.
		</div>
	);
}

function ReportOptionsComponent(props: { reportOptions: ReportOptions, onOptionsUpdated: (options: ReportOptions) => void }) {

	const [reportOptions, setReportOptions] = useState<ReportOptions>(props.reportOptions);

	useEffect(() => {
		setReportOptions(props.reportOptions);
	}, [props.reportOptions]);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		let target = event.target;
		let value = target.type === "checkbox" ? target.checked : target.value;
		let name = target.name;

		// Ensure freshnessDays is between 1 and 10
		if (name === 'freshnessDays') {
			if (parseInt(value as string) > 10) {
				value = '10';
			}
			if (parseInt(value as string) < 1) {
				value = '1';
			}
		}

		let newOption = {
			[name]: value
		}
		setReportOptions({
			...reportOptions,
			...newOption
		});
	}

	function handleUpdate() {
		props.onOptionsUpdated(reportOptions);
	}

	return (
		<div className="ReportOptions">
			<b>Report Options</b>
			<label>
				Score: <input name='score' type="number" step="0.001" value={reportOptions.score} onChange={handleChange} />
			</label>
			<label>
				Product Freshness (# days): <input name='freshnessDays' type="number" step="1" min="1" max="10" value={reportOptions.freshnessDays} onChange={handleChange} />
			</label>

			<label>
				Include Cleared: <input name='includeCleared' type="checkbox" checked={reportOptions.includeCleared} onChange={handleChange} />
			</label>

			<label>
				Include DMs: <input name='includeDMs' type="checkbox" checked={reportOptions.includeDMs} onChange={handleChange} />
			</label>

			<label>
				Include Stitching Only: <input name='includeStitchingOnly' type="checkbox" checked={reportOptions.includeStitchingOnly} onChange={handleChange} />
			</label>

			<label>
				Include Ridged Sole Only: <input name='includeRidgedSoleOnly' type="checkbox" checked={reportOptions.includeRidgedSoleOnly} onChange={handleChange} />
			</label>
			<button onClick={handleUpdate}>Update</button>
		</div>
	)
}
