import React, { useEffect, useState } from "react"
import ClientAPI, { Source } from "../../dao/ClientAPI";
import { Scan, Scraper, ScraperConfig, Tag, TimeUnit } from "../../domain/Core";
import { Error, Add } from "@mui/icons-material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import './ScrapersPage.css';
import CreateScraperDialog from "./ScraperForm";
import { Button, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { ScrapersTable } from "./ScrapersTable";
import ErrorCard from "../../components/utils/ErrorCard";
import LoadingView from "../../components/utils/LoadingView";

const DEFAULT_SCRAPER_CONFIG: ScraperConfig = {
	"id": null,
	"name": null,
	"source_id": null,
	"source_name": null,
	"last_modified": null,
	"schedule": {
		"type": "PERIODIC",
		"period": {
			"value": 60,
			"unit": TimeUnit.MINUTES
		},
		"enabled": true
	},
	"config": {
		"crawler": "debug",
		"command": "category",
		"args": {"category_name": "SOCKS"}
	}
};

/**
 * Customised alert
 */
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ScrapersPage() {

	const [scrapers, setScrapers] = useState<Scraper[] | null>(null);
	const [scrapersError, setScrapersError] = useState<Error | null>(null);
	const [reloadKey, setReloadKey] = useState(0);

	const [sources, setSources] = useState<Source[] | null>(null);

	const [editting, setEditting] = useState<ScraperConfig | null>(null);
	const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);

	const [deletting, setDeletting] = useState<ScraperConfig | null>();
	const [alertMessage, setAlertMessage] = useState<string | null>();

	const [tags, setTags] = useState<Tag[] | null>(null);
	const [runClicked, setRunClicked] = useState<Set<number>>(new Set());

	/**
	 * Load scrapers when page loaded
	 */
	useEffect(() => {
		refresh();
	}, [reloadKey]);

	async function refresh() {
		return ClientAPI.scraper.list()
			.then(setScrapers)
			.catch(setScrapersError);
	}

	/**
	 * Refresh the scrapers on an interval
	 */
	useEffect(() => {
		const interval = setInterval(() => {
			setReloadKey((cur) => cur + 1);
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	// Load sources
	useEffect(() => {
		ClientAPI.source.list()
			.then(setSources)
			.catch((e) => console.error(`Error loading sources - ${e}`));
	}, [])

	// Load tags
	useEffect(() => {
		ClientAPI.tag.list()
			.then(setTags)
			.catch((e) => console.error(`Error loading tags - ${e}`));
	}, []);


	function handleRetry() {
		setScrapersError(null);
		ClientAPI.scraper.list()
			.then(setScrapers)
			.catch(setScrapersError);
	}

	function handleEditClicked(scraper: Scraper) {
		setEditting(scraper);
		setOpenCreateForm(true);
	}

	function handleDeleteClicked(scraper: Scraper) {
		setDeletting(scraper);
	}

	function handleCreateClicked() {
		setEditting(DEFAULT_SCRAPER_CONFIG);
		setOpenCreateForm(true);
	}

	function saveScraper(scraper: ScraperConfig) {
		closeEditor();
		ClientAPI.scraper.save(scraper)
			.then(() => refresh())
			.catch(showErrorAlert);
	}

	function closeEditor() {
		setEditting(null);
		setOpenCreateForm(false);
	}

	function handleTriggerClicked(scraper: Scraper) {
		if (scraper.id != null) {
			setRunClicked(new Set([...runClicked, scraper.id]));
		}

		ClientAPI.scraper.run(scraper)
			.then(() => refresh())
			.catch(showErrorAlert)
			.finally(() => {
				let newRunClicked = new Set(runClicked);
				newRunClicked.delete(scraper.id ?? -1);
				setRunClicked(newRunClicked);
			});
	}

	function handleAlertClose() {
		setAlertMessage(null);
	}

	function closeDelete() {
		setDeletting(null);
	}

	function handleDeleteConfirmed() {
		if (deletting == null) {
			return;
		}

		let toDelete = deletting;
		setDeletting(null);
		ClientAPI.scraper.delete(toDelete)
			.then(() => refresh())
			.catch(showErrorAlert);
	}

	function handleStopClicked(scan: Scan) {
		ClientAPI.scan.stopScan(scan.id)
			.then(() => refresh())
			.catch(showErrorAlert);
	}

	function showErrorAlert(error: Error) {
		setAlertMessage(`Error: ${error.message}`);
	}

	function handleTagCreated(tag: Tag) {
		if (tags == null) {
			return;
		}

		setTags([...tags, tag]);
	}

	if (scrapersError) {
		return <ErrorCard error={scrapersError} onRetry={handleRetry} />;
	}

	if (scrapers === null) {
		return <LoadingView />
	}

	return (
		<div className="scrapers-container">
			{ /** Configure actions */}
			<h3>Actions</h3>
			<Button
				variant="contained"
				startIcon={<Add />}
				onClick={handleCreateClicked}
				disabled={sources == null || tags == null}
			>
				Create scraper
			</Button>

			{ /** Create scraper dialog */ }
			{sources && tags && editting &&
				<CreateScraperDialog
					open={openCreateForm}
					sources={sources}
					tags={tags}
					scraper={editting}
					onClose={closeEditor}
					onSubmit={saveScraper}
					onTagCreated={handleTagCreated}
				/>
			}

			{ /** Configure alerts */}
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={alertMessage != null}
				autoHideDuration={3000}
				onClose={handleAlertClose}>
				<Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
					{alertMessage}
				</Alert>
			</Snackbar>

			{ /** Confirm delete dialog */}
			{deletting &&
				<Dialog
					open={deletting != null}
					onClose={closeDelete}
				>
					<DialogTitle>
						{"Please confirm"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{`Are you sure you want to delete this scraper '${deletting.id} (${deletting.source_name} - ${deletting.source_id})'?`}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={closeDelete}>Cancel</Button>
						<Button onClick={handleDeleteConfirmed} autoFocus>Confirm</Button>
					</DialogActions>
				</Dialog>
			}

			{ /** Scrapers table */}
			<h3>Scrapers</h3>
			<ScrapersTable
				scrapers={scrapers}
				runClicked={runClicked}
				onEditClicked={handleEditClicked}
				onTriggerClicked={handleTriggerClicked}
				onDeleteClicked={handleDeleteClicked}
				onStopClicked={handleStopClicked}
			/>
		</div>
	);
}
