import { FormGroup, FormLabel, TextField } from "@mui/material";
import React, { useLayoutEffect } from "react";
import { useQuery } from 'react-query'
import LoadingView from "../../components/utils/LoadingView";
import ErrorCard from "../../components/utils/ErrorCard";
import ClientAPI from "../../dao/ClientAPI";

export default function ScraperConfigForm({crawlerConfig, showError, onConfigChanged}: {crawlerConfig: string, showError: boolean, onConfigChanged: (config: string) => void}) {

	const inputRef = React.useRef<HTMLInputElement>();
	const [cursorPosition, setCursorPosition] = React.useState<number>(0);

	function handleConfigChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		updateCursorPosition();
		onConfigChanged(e.target.value)
	}

	function updateCursorPosition() {
		if (inputRef.current?.selectionStart) {
			setCursorPosition(inputRef.current.selectionStart);
		}
	}

	/** The function allows us to override the default behaviour of the 'tab' key.
	 *  It would typically skip to the next form input but we sometimes want to
	 *  be able to add tabs to the current input
	 */
	function handleTab() {
		if (!inputRef.current) {
			return;
		}

		let cursor = inputRef.current.selectionStart;
		if (!cursor) {
			return;
		}

		let newConfig = crawlerConfig.substring(0, cursor) + '	' + crawlerConfig.substring(cursor);

		setCursorPosition((cur) => cur + 1);

		onConfigChanged(newConfig);
	}

	useLayoutEffect(() => {
		if (inputRef.current) {
			inputRef.current?.setSelectionRange(cursorPosition, cursorPosition);
		}
	}, [crawlerConfig, cursorPosition]);

	return (
		<FormGroup sx={{ gap: 2 }}>
			<FormLabel component="legend">Configure the crawler</FormLabel>
			<DefaultCrawlerConfig />
			<TextField
				inputProps={{
					onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
						if (e.code === 'Tab') {
							e.preventDefault();
							handleTab();
						}
					}
				}}
				label="Crawler configuration (JSON)"
				multiline
				rows={12}
				onChange={handleConfigChange}
				onSelect={updateCursorPosition}
				inputRef={inputRef}
				value={crawlerConfig}
				error={showError}
			/>
		</FormGroup>
	);
}

function DefaultCrawlerConfig() {

	const { status, error, data: defaultConfig, refetch } = useQuery('crawlers-default-config', () => ClientAPI.crawler.getDefaultConfig());

	if (status === 'loading' || status === 'idle') {
		return <LoadingView message="Loading default crawler configuration..." />
	}

	if (status === 'error') {
		return <ErrorCard error={error} onRetry={refetch} />
	}

	return (
		<TextField
			label="Defaults"
			multiline
			rows={4}
			disabled
			size="small"
			value={JSON.stringify(defaultConfig, null, 3)}
		/>
	)

}
