import { Error as ErrorIcon } from "@mui/icons-material";
import { Button, Card } from "@mui/material";
import "./ErrorCard.css";

export default function ErrorCard(props: {error: any, onRetry?: () => void}) {

	let errorMessage = props.error instanceof Error ? props.error.message : props.error.toString();

	return (
		<div className="error-container">
			<Card className="error-card">
				<div className="error-title">
					<ErrorIcon/>
					<h3>Something went wrong</h3>
				</div>
				{props.error.message && <span>Here's the error: "{errorMessage}"</span>}

				{props.onRetry &&
					<Button onClick={props.onRetry}>
						Retry
					</Button>
				}
			</Card>
		</div>
	);
}
