import React from 'react';
import { Link } from 'react-router-dom';
import { State, User } from '../domain/Core';
import LoginButton from './auth/UserWidget';
import { FlexSpacer } from './basic/Layout';

import "./Header.css"


export default function Header(props: {user: State<User>}){
	return (<header className="Header">
		<nav>
			{props.user.value!=null?
				<>
					<Link to="/">Home</Link>
					<Link to="/report">Report</Link>
					{window.__RUNTIME_CONFIG__.SHOW_RICHEMONT_REPORT === "true" && <Link to="/richemont/report">Richemont Report</Link>}
					<Link to="/scrapers">Scrapers</Link>
				</>
				:
				<></>
			}
			<FlexSpacer/>
			<LoginButton user={props.user}/>
		</nav>
	</header>)
}
