import { useEffect, useState } from "react";
import { IconButton, Snackbar } from "@mui/material";
import Lightbox, { ImagesListType } from 'react-spring-lightbox';
import CloseIcon from '@mui/icons-material/Close';
import "./CoolLightBox.css";

export default function CoolLightbox(props: { show: boolean, images: any, initialSelection: number, onClose: () => void }) {

	let images: ImagesListType = props.images.map((i: any) => ({
		src: i,
		loading: 'lazy',
		alt: ''
	}));

	const [selected, setSelected] = useState(props.initialSelection);

	const ctrlKeyPressed = useKeyPress('Control');

	return (
		<div>
			<Lightbox
				className={ctrlKeyPressed ? "lightbox-zoom" : ""}
				isOpen={true}
				images={images}
				onClose={props.onClose}
				style={{ background: "#000000aa" }}
				currentIndex={selected}
				onNext={() => setSelected((i: number) => Math.min(props.images.length - 1, i + 1))}
				onPrev={() => setSelected((i: number) => Math.max(0, i - 1))}
				pageTransitionConfig={{
					from: { opacity: 0 },
					enter: { opacity: 1 },
					leave: { opacity: 0 },
				}}
				singleClickToZoom={true}
			/>
			<LightboxHintSnackbar />
		</div>
	);
};

function LightboxHintSnackbar() {

	const [open, setOpen] = useState(true);

	const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const action = (
		<>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	return (
		<Snackbar
			open={open}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			onClose={handleClose}
			message="Zoom with (Ctrl + Mousewheel), (Pinch) or (Single Click)"
			action={action}
		/>
	);
}

function useKeyPress(targetKey: string) {

	const [keyPressed, setKeyPressed] = useState<boolean>(false);

	useEffect(() => {
		function downHandler({ key }: { key: string }) {
			if (key === targetKey) {
				setKeyPressed(true);
			}
		}

		const upHandler = ({ key }: { key: string }) => {
			if (key === targetKey) {
				setKeyPressed(false);
			}
		};

		window.addEventListener("keydown", downHandler);
		window.addEventListener("keyup", upHandler);

		return () => {
			window.removeEventListener("keydown", downHandler);
			window.removeEventListener("keyup", upHandler);
		};
	}, [targetKey]);

	return keyPressed;
}
