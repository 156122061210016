import { FormGroup, FormLabel, FormControlLabel, Switch, TextField, MenuItem } from "@mui/material";
import { ScheduleConfig, SchedulePeriod, TimeUnit } from "../../domain/Core";

export default function ScheduleForm(props: {schedule: ScheduleConfig, onScheduleChanged: (schedule: ScheduleConfig) => void}) {

	function handleTypeChanged(type: string) {
		props.onScheduleChanged({
			...props.schedule,
			type
		});
	}

	function handlePeriodChanged(period: SchedulePeriod) {
		props.onScheduleChanged({
			...props.schedule,
			period
		});
	}

	function handleEnabledChanged(enabled: boolean) {
		props.onScheduleChanged({
			...props.schedule,
			enabled: enabled
		});
	}

	return (
		<FormGroup sx={{ gap: 2 }}>
			<FormLabel component="legend">Configure a schedule</FormLabel>
			<FormControlLabel
				control={
					<Switch
						checked={props.schedule.enabled}
						onChange={(e) => handleEnabledChanged(e.target.checked)}
						name="enabled" />
				}
				label="Enabled"
			/>
			{props.schedule.enabled &&
				<TextField
					value={"PERIODIC"}
					onChange={(e) => handleTypeChanged(e.target.value)}
					select
					label="Select the schedule type"
					fullWidth
				>
					<MenuItem key={1} value={'PERIODIC'}>
						Periodic
					</MenuItem>
				</TextField>
			}
			{props.schedule.enabled &&
				(props.schedule.type === 'PERIODIC' ?
					<PeriodicScheduleForm
						schedulePeriod={props.schedule.period}
						onPeriodChanged={handlePeriodChanged} />
					:
					<div>{`Schedule type '${props.schedule.type}' not currently supported`}</div>
				)
			}

		</FormGroup>
	);
}

function PeriodicScheduleForm({schedulePeriod, onPeriodChanged}: {schedulePeriod: SchedulePeriod, onPeriodChanged: (period: SchedulePeriod) => void}) {
	return (
		<>
			<TextField
				value={schedulePeriod.value}
				onChange={(e) => onPeriodChanged({...schedulePeriod, value: parseInt(e.target.value)})}
				type="number"
				label="Select an interval"
				fullWidth
				InputProps={{
					inputProps: { min: 1 }
				}}
			>
			</TextField>
			<TextField
				value={schedulePeriod.unit}
				onChange={(e) => onPeriodChanged({...schedulePeriod, unit: TimeUnit[e.target.value as keyof typeof TimeUnit]})}
				select
				label="Select a unit"
				fullWidth>
				{Object.keys(TimeUnit).map((unit: string, i: number) =>
					<MenuItem key={i} value={unit.toString()}>
						{unit}
					</MenuItem>
				)}
			</TextField>
		</>
	)
}
