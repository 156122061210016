import { FormGroup, FormLabel, Box, Chip, Dialog, DialogTitle, DialogContent, FormControl, TextField, DialogActions, Button, CircularProgress, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import ClientAPI from "../../dao/ClientAPI";
import { Tag } from "../../domain/Core";

export default function TagsForm(props: {tags: Tag[], selectedTags: number[], onTagSelectionUpdated: (tags: number[]) => void, onTagCreated: (tag: Tag) => void}) {
	return (
		<FormGroup sx={{ gap: 2 }}>
			<FormLabel component="legend">Configure tags</FormLabel>
			<TagSelector
				tags={props.tags}
				selectedTags={props.selectedTags}
				onTagSelectionUpdated={props.onTagSelectionUpdated}
				onTagCreated={props.onTagCreated}
			/>
		</FormGroup>
	)
}

function TagSelector(props: {tags: Tag[], selectedTags: number[], onTagSelectionUpdated: (tags: number[]) => void, onTagCreated: (tag: Tag) => void}) {

	const [showTagCreator, setShowTagCreator] = useState(false);

	function handleTagClicked(tag: Tag) {
		if (tag.id == null) {
			return;
		}

		if (props.selectedTags.includes(tag.id)) {
			props.onTagSelectionUpdated(props.selectedTags.filter(t => t !== tag.id));
		} else {
			props.onTagSelectionUpdated([...props.selectedTags, tag.id]);
		}
	}

	function handleTagCreated(tag: Tag) {
		setShowTagCreator(false);

		if (tag.id == null) {
			return;
		}

		props.onTagCreated(tag);
		props.onTagSelectionUpdated([...props.selectedTags, tag.id]);
	}

	return (
		<>
			{ /** Create tag creator dialog */ }
			{showTagCreator &&
			 	<CreateTagCreatorDialog
			 		open={showTagCreator}
			 		tags={props.tags}
			 		onClose={() => setShowTagCreator(false)}
			 		onTagCreated={handleTagCreated} />
			}

			<Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
				{props.tags.map((tag) =>
					<Chip
						sx={{ margin: 0.5 }}
						key={tag.id}
						label={tag.name}
						variant={props.selectedTags.includes(tag.id ?? -1) ? "filled" : "outlined"}
						color={props.selectedTags.includes(tag.id ?? -1) ? "success" : "primary"}
						onClick={() => handleTagClicked(tag)} />
				)}
				<Button
					sx={{ margin: 0.5 }}
					key={-1}
					onClick={() => setShowTagCreator(true)} >
					Create tag
				</Button>
			</Box>
		</>
	);
}

type CreateTagCreatorDialogProps = {
	open: boolean,
	tags: Tag[],
	onTagCreated: (tag: Tag) => void,
	onClose: () => void
}
export function CreateTagCreatorDialog(props: CreateTagCreatorDialogProps) {

	const [name, setName] = useState<string | null>(null);
	const [nameError, setNameError] = useState<string | null>(null);

	const [description, setDescription] = useState<string | null>(null);

	const [submitting, setSubmitting] = useState(false);

	const [alertMessage, setAlertMessage] = useState<string | null>();

	function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
		// Clear previous error
		setNameError(null);

		let candidateName = event.target.value;

		// Validate name
		let error = getNameError(candidateName);
		if (error !== null) {
			setNameError(error);
		}

		setName(candidateName);
	};

	function getNameError(candidateName: string | null) {
		// Check for empty string
		if (candidateName === null || candidateName === '') {
			return 'Invalid tag name';
		}

		// Check if that name already exists
		if (props.tags.filter((t) => t.name === candidateName).length > 0) {
			return 'A tag with that name already exists';
		}

		return null;
	}

	function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
		let candidateDescription = event.target.value;

		// Check for empty string
		if (candidateDescription === '') {
			return;
		}

		setDescription(candidateDescription);
	}

	function handleSave() {
		// Check if we are already submitting
		if (submitting) {
			return;
		}

		// Validate name
		let error = getNameError(name);
		if (error !== null) {
			setNameError(error);
			return;
		}

		setSubmitting(true);

		let tag = {
			name: name as string, // We've already checked that it's not null
			description: description ?? ''
		}

		ClientAPI.tag.create(tag)
			.then(props.onTagCreated)
			.catch((e) => {
				setSubmitting(false);
				showErrorAlert(e);
			});

	}

	function showErrorAlert(error: Error) {
		setAlertMessage(`Error: ${error.message}`);
	}

	function handleAlertClose() {
		setAlertMessage(null);
	}

	return (
		<Dialog open={props.open ?? false} onClose={props.onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Create a new tag</DialogTitle>
			<DialogContent>
				<FormControl fullWidth sx={{ gap: 2 }}>
					<FormGroup sx={{ gap: 2 }}>
						<TextField
							onChange={handleNameChange}
							margin="dense"
							label="Name"
							fullWidth
							error={nameError !== null}
							helperText={nameError}
						/>
						<TextField
							onChange={handleDescriptionChange}
							margin="dense"
							label="Description"
							fullWidth
						/>

					</FormGroup>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button onClick={handleSave} disabled={submitting}>
					{submitting ?
						<CircularProgress size={20} />:
						"Create"
					}
				</Button>
			</DialogActions>

			{ /** Configure alerts */}
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={alertMessage != null}
				autoHideDuration={3000}
				onClose={handleAlertClose}>
				<Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
					{alertMessage}
				</Alert>
			</Snackbar>
		</Dialog>
	)
}
