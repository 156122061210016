import { ReportOptions, Report, ReportEntry } from "../../domain/RichemontTypes";

function validScore(score: number | null, threshold: number) {
	if (score == null) {
		return false;
	}
	return score >= threshold;
}

type ReportSummaryProps = {
	report: Report,
	reportOptions: ReportOptions
}
export default function ReportSummary(props: ReportSummaryProps) {

	const threshold = props.reportOptions.score;

	function validYellow(entry: ReportEntry) {
		return validScore(entry.scores.yellow_contrast_stitching, threshold);
	}

	function validNonYellow(entry: ReportEntry) {
		return validScore(entry.scores.non_yellow_contrast_stitching, threshold);
	}

	function validRidged(entry: ReportEntry) {
		return validScore(entry.scores.ridged_sole, threshold);
	}

	const numWithoutScores = props.report.filter(e => !validYellow(e) && !validNonYellow(e) && !validRidged(e)).length;

	return (
		<div className="ReportSummary">
			<b>Report Summary</b>
			<ul>
				<li>Score threshold - {props.reportOptions.score}</li>
				<li>Including products appearing in the feeds since - {new Date(Date.now() - props.reportOptions.freshnessDays * 24 * 60 * 60 * 1000).toLocaleDateString()}</li>
				<li>Size of report - {props.report.length}</li>
				<li>{props.report.filter(e => validYellow(e) && validRidged(e)).length} product(s) with yellow contrast coloured welt stitching and a ridged sole edge</li>
				<li>{props.report.filter(e => validYellow(e) && !validRidged(e)).length} product(s) with yellow contrast coloured welt stitching only</li>
				<li>{props.report.filter(e => !validYellow(e) && validNonYellow(e) && validRidged(e)).length} product(s) with non-yellow contrast coloured welt stitching and a ridged sole edge</li>
				<li>{props.report.filter(e => !validYellow(e) && validNonYellow(e) && !validRidged(e)).length} product(s) with non-yellow contrast coloured welt stitching only</li>
				<li>{props.report.filter(e => !validYellow(e) && !validNonYellow(e) && validRidged(e)).length} product(s) with ridged sole only</li>
				{
					numWithoutScores > 0 ?
						<li>{numWithoutScores} product(s) with pending feedback</li> : null
				}
			</ul>
		</div>
	);
}
