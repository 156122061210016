import "./ScoresChanger.css"
import { useState, useEffect, ChangeEvent } from "react";
import { ReportEntry } from "../../domain/RichemontTypes";
import { API } from "../../dao/RichemontAPI";

type ScoresUpdatedCallback = (productId: number, scores: Record<string, number>) => void;
export default function ScoresChanger(props: { reportEntry: ReportEntry, onScoresUpdated: ScoresUpdatedCallback }) {

	const [scores, setScores] = useState<Record<string, number>>(props.reportEntry.scores);
	const [updateScoresError, setUpdateScoresError] = useState<null | Error>();

	useEffect(() => {
		setScores(props.reportEntry.scores);
	}, [props.reportEntry]);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		let target = event.target;
		let value = parseFloat(target.value);
		let name = target.name;

		let newScore = {
			[name]: value
		}
		setScores({
			...scores,
			...newScore
		});
	}

	async function handleUpdate() {
		let currentScores: Record<string, number> = props.reportEntry.scores;

		let shouldUpdateReport = false;
		for (let [name, score] of Object.entries(scores)) {
			// Skip is score hasn't changed
			if (currentScores[name] === score) {
				continue;
			}

			try {
				await API.writeScore(props.reportEntry.id, name, score);
				shouldUpdateReport = true;
			} catch (e) {
				if (e instanceof Error) {
					setUpdateScoresError(e);
				} else {
					setUpdateScoresError(new Error("Unknown error"));
				}
			}
		}

		if (shouldUpdateReport) {
			props.onScoresUpdated(props.reportEntry.id, scores);
		}
	}

	return (
		scores == null ?
			<p>Error loading current scores</p>
			:
			<div>
				<div>
					<b>Scores</b>
				</div>
				<div className="ScoreChangerForm">
					{
						Object.entries(scores).map(([key, value]) =>
							<label key={key}>
								{key}: <input name={key} type="number" step="0.001" value={value ?? ''} onChange={handleChange} />
							</label>
						)
					}
					<button onClick={handleUpdate}>Update</button>
					{
						updateScoresError != null ?
							<div className="ScoreChangerFormError">
								<div>Error updating scores</div>
								<div>{updateScoresError.message}</div>
								<div>Please notify a developer and/or try again later.</div>
							</div>
							:
							null
					}
				</div>
			</div>
	);
}
