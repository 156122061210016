import React from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import { User } from './domain/Core.js';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import ClientAPI from './dao/ClientAPI';
import {API as RichemontAPI} from './dao/RichemontAPI';

import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import ReportPage from './pages/report/ReportPage';
import UserProfilePage from './pages/user/ProfilePage';
import ItemPage from './pages/item/ItemPage';

import RichemontReportPage from './pages/richemont/RichemontReportPage';

import Header from './components/Header';
import Footer from './components/Footer';

import ScrapersPage from './pages/scrapers/ScrapersPage';
import ScanStatsPage from './pages/scrapers/ScanStatsPage';

import { QueryClient, QueryClientProvider } from 'react-query'


const queryClient = new QueryClient();

const pca = new PublicClientApplication({
	auth: {
		clientId: "7eb5fe5c-3ce1-4922-b56a-8af0e28db63e"
	}
});

type ApplicationState = {
	user: User | null
};

export default class Application extends React.Component<any, ApplicationState>{
	constructor(props: any){
		super(props);

		this.state = {
			user: null
		}
		ClientAPI.setServer(window.__RUNTIME_CONFIG__.REACT_APP_PORTAL_SERVER);

		this.onUserChange = this.onUserChange.bind(this);
	}

	onUserChange(user: any){
		ClientAPI.setUser(user);
		RichemontAPI.setUser(user);
		this.setState({user});
	}

	render(): React.ReactNode {
		let userstate = {value: this.state.user, onChange: this.onUserChange};

		return <QueryClientProvider client={queryClient}>
			<MsalProvider instance={pca}>
				<HashRouter> {/* HashRouter as we don't want to have hundreds of different urls to register for MSAL*/}
					<Header user={userstate}/>
					<main>
						{this.state.user!=null?
							<Routes>
								<Route path='/' element={<HomePage/>}/>
								<Route path='/report' element={<ReportPage/>}/>
								<Route path='/item/:source/:itemRef' element={<ItemPage/>}/>
								<Route path='/richemont/report' element={<RichemontReportPage/>}/>
								<Route path='/user/profile' element={<UserProfilePage/>}/>
								<Route path='/scrapers' element={<ScrapersPage />}/>
								<Route path='/scan/:scanId/stats' element={<ScanStatsPage/>}/>
							</Routes>
							:
							<LoginPage user={userstate}/>}
					</main>
					<Footer/>
				</HashRouter>
			</MsalProvider>
		</QueryClientProvider>;
	}
}
