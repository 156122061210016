import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import { State, User } from "../../domain/Core.js";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { InteractionStatus } from '@azure/msal-browser';

export default function UserWidget(props: {user: State<User>}){
	const { instance, accounts, inProgress } = useMsal();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	React.useEffect(()=>{
		if (inProgress === InteractionStatus.None && accounts.length > 0) {
			// check if we have now (automatically) logged in - update the application state if so
			if(props.user.value?.username !== accounts[0].username){
				// after we've finished rendering - instruct the Application that we have changed the User
				props.user.onChange({
					system: "MSAL",
					username: accounts[0].username,
					token: async ()=>{
						let res = await instance.acquireTokenSilent({scopes: [], account: accounts[0], forceRefresh: false})
						return res.accessToken;
					}
				});
			}
		}
	}, [inProgress, accounts, instance, props]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	if (accounts.length > 0) {
		// if the user is logged in
		return <div>
			<Button
				id="basic-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				{accounts[0].username}
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem component={Link} to="/user/profile">My Profile</MenuItem>
				<MenuItem onClick={() => instance.logoutPopup().then(()=>{handleClose();props.user.onChange()})}>Logout</MenuItem>
			</Menu>
		</div>
	} else if (inProgress === "login") {
		return <span>Login is currently in progress!</span>
	} else {
		return (
			<>
			</>
		);
	}
}
