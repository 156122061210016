import { User, Scraper, ScraperConfig, Tag, Scan, ExtraStats } from "../domain/Core.js";

export class ClientAPI{

	server: string = "http://localhost:3081";
	#user: User | null = null;

	item: ItemAPI = new ItemAPI(this);
	source: SourceAPI = new SourceAPI(this);
	feature: FeatureAPI = new FeatureAPI(this);
	report: ReportAPI = new ReportAPI(this);
	scraper: ScraperAPI = new ScraperAPI(this);
	scan: ScanAPI = new ScanAPI(this);
	crawler: CrawlerAPI = new CrawlerAPI(this);
	tag: TagAPI = new TagAPI(this);

	constructor(){
		this.init();
	}

	setUser(user: User){
		this.#user = user;
	}

	setServer(server?: string){
		this.server = server ?? "http://localhost:3081";
	}

	async init(){
		// TODO
	}

	get url(){
		// eslint-disable-next-line no-restricted-globals
		return this.server;
	}

	async #fetch(method: "GET"|"POST"|"DELETE", path: string, body?: string){
		let token = null;
		if(this.#user?.system === "MSAL"){
			token = await this.#user.token();
		}

		if(!token){
			/// HMMMM no user maybe just wait and try again?
			throw new Error(`Request not attempted - user has no valid token`);
		}

		try{
			let args: any = {
				method: method,
				headers: {
					authorization:`MSAL:Bearer ${token}`,
					'content-type': "application/json"
				}
			};
			if(body && (method === "POST" || method === "DELETE")){
				args.body = body
			}
			let resp = await fetch(this.url+path, args);
			if(resp.status !== 200){
				// validation error
				if(resp.status === 400){
					// TODO show a warning to the user somehow
					let json = await resp.json();
					throw new Error(`"${path}" Request returned validation error: ${json.error}`);
				}
				// error
			 	throw new Error(`"${path}" Request returned non 200 status - ${resp.status}`);
			}
			let json = await resp.json();
			return json.data;
		}catch(e){
			console.warn(e);
			throw e;
		}
	}

	async _get(path: string){
		return this.#fetch("GET", path);
	}

	async _post(path: string, body: string){
		return this.#fetch("POST", path, body);
	}

	async _delete(path: string, body: string){
		return this.#fetch("DELETE", path, body)
	}

	async status(){
		let resp = await fetch(this.url+"/status");
		return resp.status === 200;
	}

}

export type Source = {
	id: number,
	name: string,
	info: any,
	config: any
}

class SourceAPI{
	private api: ClientAPI;
	constructor(api: ClientAPI){
		this.api = api;
	}

	async list(): Promise<Source[]>{
		return this.api._get(`/sources`);
	}
}

export type Feature = {
	id: number,
	name: string,
	info: any
}

class FeatureAPI{
	private api: ClientAPI;
	constructor(api: ClientAPI){
		this.api = api;
	}

	async list(): Promise<Feature[]>{
		return this.api._get(`/features`);
	}
}

class ItemAPI{
	private api: ClientAPI;
	constructor(api: ClientAPI){
		this.api = api;
	}

	async get(sourceId: number, externalRef: string): Promise<any>{
		return this.api._get(`/item/${sourceId}/${encodeURIComponent(externalRef)}`);
	}

}

class ScraperAPI {

	private api: ClientAPI;

	constructor(api: ClientAPI) {
		this.api = api;
	}

	async list(): Promise<Scraper[]> {
		return await this.api._get(`/scrapers`);
	}

	async save(scraper: ScraperConfig): Promise<void> {
		return await this.api._post('/scraper', JSON.stringify({scraper}));
	}

	async delete(scraper: ScraperConfig): Promise<void> {
		return await this.api._delete('/scraper', JSON.stringify({scraper}));
	}

	async run(scraper: Scraper): Promise<void> {
		return await this.api._post('/scraper/run', JSON.stringify({scraper}));
	}
}

class ScanAPI {

	private api: ClientAPI;

	constructor(api: ClientAPI) {
		this.api = api;
	}

	async get(scanId: number): Promise<Scan> {
		return await this.api._get(`/scan/${scanId}`);
	}

	async getExtraStats(scanId: number): Promise<ExtraStats> {
		return await this.api._get(`/scan/${scanId}/stats`);
	}

	async getRecentItems(scanId: number): Promise<any[]> {
		return await this.api._get(`/scan/${scanId}/recent`);
	}

	async stopScan(scanId: number): Promise<void> {
		return await this.api._post(`/scan/stop`, JSON.stringify({scanId}));
	}
}

class CrawlerAPI {

	private api: ClientAPI;

	constructor(api: ClientAPI) {
		this.api = api;
	}

	async getDefaultConfig(): Promise<any> {
		return await this.api._get('/crawler-defaults');
	}

}

class TagAPI {

	private api: ClientAPI;

	constructor(api: ClientAPI) {
		this.api = api;
	}

	async list(): Promise<Tag[]> {
		return await this.api._get(`/tags`);
	}

	async create(tag: Tag): Promise<Tag> {
		let resp = await this.api._post('/tag', JSON.stringify({tag}));
		return resp.tag;
	}

}

export type ReportFilter = {
	sources?: any,
	features?: any
}

export type ReportSort = {

}

class ReportAPI{
	private api: ClientAPI;
	constructor(api: ClientAPI){
		this.api = api;
	}

	async get(reportFilter: ReportFilter, reportSort: ReportSort, page: number){
		// map the fetures filters into an array
		let featureFilters = [];
		if(reportFilter.features){
			for(let [key,value] of Object.entries(reportFilter.features)){
				featureFilters.push(Object.assign({},{id: key},value));
			}
		}
		return this.api._post(`/report?index=${page}`, JSON.stringify({filter: {sources: reportFilter.sources, features: featureFilters}, sort: reportSort}));
	}
}

export default new ClientAPI();
