export type User = {
	system: "MSAL"
	username: string
	token: ()=>Promise<string>
}

export type State<T> = {
	value: T | null
	onChange: (newValue?:T)=>void
}

export type UserProfile = {
	id: number;
	email: string;
	name: string;
	created_at: string;
}

export enum TimeUnit {
	SECONDS = "SECONDS",
	MINUTES = "MINUTES",
	HOURS = "HOURS",
	DAYS = "DAYS",
	WEEKS = "WEEKS"
};

export type SchedulePeriod = {
	unit: TimeUnit,
	value: number
};

export type ScheduleConfig = {
	type: string,
	period: SchedulePeriod,
	enabled: boolean
};

export type ScraperConfig = {
	id: number | null,
	name: string | null,
	source_id: number | null,
	source_name: string | null,
	schedule: ScheduleConfig,
	config: Record<string, any>,
	last_modified: string | null
};

export type ScraperState = {
	healthy: boolean | null,
	status: string | null,
	scans: Scan[] | null
}

export type Scraper = ScraperConfig & ScraperState;

export type Scan = {
	id: number,
	started_at?: string,
	completed_at?: string,
	status: string,
	stats?: ScanStats
};

export type ScanStats = {
	updated_at: string,
	crawler_manager: {
		items: {
			error: number,
			total: number,
			cached: number
		}
	},
	item_storage_service: {
		items: {
			error: number,
			created: number,
			updated: number
		},
		images: {
			error: number,
			created: number,
			downloaded: number
		}
	}
}

export type CountsData = [number, number];

export type ExtraStats = {
	crawler_manager: {
		items: {
			total: CountsData[],
			cached: CountsData[],
			error: CountsData[]
		}
	},
	item_storage_service: {
		items: {
			created: CountsData[],
			updated: CountsData[],
			error: CountsData[]
		},
		images: {
			created: CountsData[],
			downloaded: CountsData[],
			error: CountsData[]
		}
	}
};

export type Tag = {
	id?: number,
	name: string,
	description: string
};
